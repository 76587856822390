<template>
  <v-card class="dispute-summary">
    <v-card-title>{{$t('t.Disputes')}}</v-card-title>
    <v-card-text v-if="hasData">
      <v-carousel
        v-if="hasCaroussel"
        cycle
        class="dispute-graph"
        width="100%"
        height="175px"
        hide-delimiter-background
        hide-delimiters
        show-arrows-on-hover
      >
        <v-carousel-item v-if="hasOpenReasons">
          <dispute-open-reason :account="account" />
        </v-carousel-item>
        <v-carousel-item v-if="hasApprovedReasons">
          <dispute-approved-reason :account="account" />
        </v-carousel-item>
        <v-carousel-item v-if="hasStatus">
          <dispute-status :account="account" />
        </v-carousel-item>
      </v-carousel>
      <dispute-stats
        :account="account"
        v-if="hasStats"
      />
    </v-card-text>
    <v-card-text
      v-else
      style="height: 243px"
    >
      <v-container
        fluid
        class="full-height pa-0 pt-n8"
      >
        <v-row
          align="center"
          class="full-height pa-0"
        >
          <v-col
            align="center"
            class="text-h2 text--disabled pa-0 mt-n8"
          >{{$t('t.NoOpenDisputes')}}</v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    DisputeApprovedReason: () => import('./dispute-approved-reason'),
    DisputeOpenReason: () => import('./dispute-open-reason'),
    DisputeStatus: () => import('./dispute-status'),
    DisputeStats: () => import('./dispute-stats')
  },
  computed: {
    disputes () {
      return this.account?.disputes || {}
    },
    hasApprovedReasons () {
      return !!(this.disputes.approvedReasons || []).length
    },
    hasCaroussel () {
      return this.hasOpenReasons || this.hasStatus || this.hasApprovedReasons
    },
    hasData () {
      return this.hasStats || this.hasCaroussel
    },
    hasOpenReasons () {
      return !!(this.disputes.openReasons || []).length
    },
    hasStats () {
      return this.disputes.openAmount || this.disputes.balance || this.disputes.approvedRate || this.disputes.rejectedRate || this.disputes.durationAvg
    },
    hasStatus () {
      return !!(this.disputes.statuses || []).length
    }
  },
  props: {
    account: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%
</style>
